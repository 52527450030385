@import url("../src/assets/fonts/kanit/font.css");
// bootstrap override classes here
$green: #88d317;
$btn-border-radius: 0rem;
$card-border-radius: 0rem;
$input-border-radius: 0rem;
@import "../node_modules/bootstrap/scss/bootstrap.scss";

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

:root {
    --header-height: 3rem;
    --nav-width: 1rem;
    --primary-color: #0e6c43;
    --first-color: #070707;
    --first-color-light: #afa5d9;
    --white-color: #f7f6fb;
    --body-font: "Nunito", sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100;
}

*,
::before,
::after {
    box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
    position: relative;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: 0.5s;
    background-color: #f7f6fb;
    //   background-image: url('/assets/images/banner.png');
    //   background-position: center center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
}

.vertical-content {
    min-height: calc(100vh - 70px);
}

.inside-content {
    padding-top: 7rem;
    padding-bottom: 1rem;
}

a {
    text-decoration: none;
}

.transbox {
    background-color: #ffffff;
    opacity: 0.9;
    height: 100%;
    width: 100%;
}

.transbox p {
    color: #000000;
    text-align: center;
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 18px;
}

.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1rem;
    background-color: var(--primary-color);
    z-index: var(--z-fixed);
    transition: 0.5s;
}

.header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;
}

.header_img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 40px;
    }
}

.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color: var(--first-color);
    padding: 0.5rem 1rem 0 0;
    transition: 0.5s;
    z-index: var(--z-fixed);
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.nav_logo,
.nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
    margin-bottom: 2rem;
}

.nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color);
}

.nav_logo-name {
    color: var(--white-color);
    font-weight: 700;
}

.nav_link {
    position: relative;
    color: var(--first-color-light);
    margin-bottom: 1.5rem;
    transition: 0.3s;

    &:hover {
        color: var(--white-color);
    }
}

.nav_icon {
    font-size: 1.25rem;
}

.show {
    left: 0;
}

.body-pd {
    padding-left: calc(var(--nav-width) + 1rem);
}

.active {
    color: var(--white-color);

    &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 2px;
        height: 32px;
        background-color: var(--white-color);
    }
}

footer {
    background-color: var(--primary-color);
    // height: var(--header-height);
}

.height-100 {
    height: 100vh;
}

.card {
    .card-header-green {
        .card-icon,
        .card-text,
        &:not(.card-header-icon):not(.card-header-text) {
            // background: linear-gradient(60deg, #0E286C, #0a2568);
            background-color: #0e6c43 !important;
        }
    }

    &.card-stats {
        background: transparent;
        display: -webkit-box;
        display: flex;
    }
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #eee;

    &:first-child {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }
}

.card [class*="card-header-"] {
    .card-icon,
    .card-text {
        border-radius: 3px;
        background-color: #999;
        padding: 15px;
        margin-top: -30px;
        margin-right: 15px;
        float: left;
    }
    .card-text {
        font-weight: bold;
        color: white;
    }
}

.form-label {
    font-size: 0.89rem;
    color: #787878;
    margin-bottom: 0px;
}

.form-control {
    border-radius: 0.3rem;
}
@media screen and (min-width: 768px) {
    .header {
        height: calc(var(--header-height) + 1rem);
    }

    .header_img {
        width: 40px;
        height: 40px;

        img {
            width: 45px;
        }
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0;
    }

    .show {
        // width: calc(var(--nav-width) + 156px);
        width: calc(var(--nav-width) + 100%);
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 188px);
    }
}
